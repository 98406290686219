import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import React from 'react';
import { TextButton } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}
const CancelSubscriptionCta = ({ subscription, actionCreators }: Props & RuntimeProps) => {
  const settings = useSettings();
  const cancelSubscriptionText = settings.get(settingsParams.cancelSubscriptionText);
  return (
    <TextButton
      data-hook="subscription-cancel-button"
      className={classes.cta}
      onClick={() => {
        actionCreators.openCancelConfirmModal(subscription.id!);
      }}
    >
      {cancelSubscriptionText}
    </TextButton>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(CancelSubscriptionCta);
