import { deNullify } from './commonUtils';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { MFieldValuePairs } from '@wix/cashier-payments-widget/dist/src/types/MandatoryFields';
import { Experiments as IExperiments } from '@wix/yoshi-flow-editor';
import { Experiments } from '../Experiments';
import { PaymentsWidgetAPI } from '@wix/cashier-payments-widget';
import { ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';

export const getPrefilleFormFields = (
  subscription: Subscription,
  experiments: IExperiments,
): MFieldValuePairs | undefined => {
  const billingAddress = subscription.billingSettings?.billingAddress;
  if (billingAddress && experiments.enabled(Experiments.PREFILL_BILLING_ADDRESS_DETAILS_IN_UPM)) {
    const { contactDetails, address } = billingAddress;
    return deNullify({
      email: contactDetails?.email,
      firstName: contactDetails?.firstName,
      lastName: contactDetails?.lastName,
      street: address?.streetAddress?.name,
      houseNumber: address?.streetAddress?.number,
      address: address?.addressLine,
      city: address?.city,
      state: address?.subdivision,
      zipCode: address?.postalCode,
      phone: contactDetails?.phone,
    });
  }
  return undefined;
};

const isValidationError = (e: any) => e?.isValid === false;

export async function fetchPaymentMethodWithFallback(
  paymentsWidgetApi: PaymentsWidgetAPI | undefined,
  errorMonitor: ErrorMonitor,
  endInteraction: () => void,
): Promise<{ paymentAgreementId: string } | undefined> {
  return paymentsWidgetApi?.getSavedPaymentMethod().catch((e) => {
    if (!isValidationError(e)) {
      errorMonitor.addBreadcrumb({
        message: 'error in payments side when trying to create/get payment method on payments cashier widget',
      });
      errorMonitor.captureException(e);
    }
    endInteraction();
    return undefined;
  });
}
