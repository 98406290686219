import React from 'react';

import { Dialog, Text, TextTypography as TYPOGRAPHY, Button, ButtonPriority as PRIORITY } from 'wix-ui-tpa';

import { useFedopsLogger, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../common/components/runtime-context';
import { getCancelConfirmModalSubscription, isCancelConfirmModalOpen } from '../selectors';
import { classes } from './CancelConfirmModal.st.css';
import { RootState } from '../state';
import { Interactions } from '../../../types/interactions';
import { isRecurringSubscription } from '../domainUtils';
import { IActionCreators } from '../../../types/internal-types';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

export const CancelConfirmModal = ({ isOpen, subscription, actionCreators }: RuntimeProps) => {
  const fedops = useFedopsLogger();
  const { t } = useTranslation();
  const isRecurring = isRecurringSubscription(subscription);
  return (
    <Dialog
      data-hook="cancel-confirm-modal"
      isOpen={isOpen}
      onClose={() => actionCreators.closeCancelConfirmModal()}
      closeButtonAriaLabel={t('app.cancel-confirm-modal.close')}
    >
      {isOpen ? (
        <>
          <div className={classes.title}>
            <Text className={classes.text} typography={TYPOGRAPHY.largeTitle}>
              {t('app.cancel-confirm-modal.title', { subscriptionName: subscription.name })}
            </Text>
          </div>
          <div data-hook="cancel-confirm-modal-description" className={classes.description}>
            <Text className={classes.text} typography={TYPOGRAPHY.runningText}>
              {t(
                isRecurring ? 'app.cancel-confirm-modal.description-recurring' : 'app.cancel-confirm-modal.description',
              )}
            </Text>
          </div>
          <div className={classes.actionContainer}>
            <Button
              className={classes.buttonPrimary}
              data-hook="CancelConfirmModal-cancel-btn"
              upgrade
              priority={PRIORITY.basicSecondary}
              onClick={() => actionCreators.closeCancelConfirmModal()}
            >
              {t('app.cancel-confirm-modal.cancel')}
            </Button>
            <Button
              className={classes.buttonSecondary}
              data-hook="CancelConfirmModal-confirm-btn"
              upgrade
              priority={PRIORITY.basic}
              onClick={() => {
                fedops.interactionStarted(Interactions.SubscriptionCancel);
                actionCreators.confirmCancel();
              }}
            >
              {t('app.cancel-confirm-modal.confirm')}
            </Button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => ({
  isOpen: isCancelConfirmModalOpen(state),
  subscription: getCancelConfirmModalSubscription(state),
  actionCreators,
});

export default connect(mapRuntimeToProps)(CancelConfirmModal);
