import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { TextButton } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

// TODO instead of this button we should have like in figma
const PayNowSectionHelper = ({ subscription, actionCreators }: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const onCtaClick = () => {
    actionCreators.openPayNowModal(subscription.id!);
  };

  return (
    <TextButton data-hook="pay-now-cta-button" className={classes.cta} onClick={onCtaClick}>
      Pay Now (ui gonna change)
    </TextButton>
  );
};

const mapRuntimeToProps = (_state: RootState, _props: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(PayNowSectionHelper);
