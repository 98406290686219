import { PaymentStatus, Subscription } from '@wix/ambassador-billing-v1-subscription/types';

export const isRecurringSubscription = (subscription: Subscription) =>
  subscription?.billingSettings &&
  (subscription.billingSettings?.totalCycles === undefined ||
    (subscription.billingSettings?.totalCycles && subscription.billingSettings?.totalCycles > 1));

export const isPendingCancellationSubscription = (subscription: Subscription) =>
  isRecurringSubscription(subscription) && subscription.billingSettings?.cycleAutoRenew === false;

export const hasAnyDiscount = (subscription: Subscription) => {
  if (subscription.billingSettings?.discounts && subscription.billingSettings?.discounts.length > 0) {
    return true;
  }

  const hasItemWithDiscount = !!subscription.items?.find((item) => item.discounts && item.discounts.length > 0);
  return hasItemWithDiscount;
};

export const isInGracePeriod = (subscription: Subscription) => {
  return (
    subscription.billingStatus?.gracePeriodData &&
    subscription.billingStatus?.latestPaymentData?.paymentStatus === PaymentStatus.FAILED
  );
};
