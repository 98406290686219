import { createSettingsParams, SettingsParamType } from '@wix/yoshi-flow-editor/tpa-settings';

type ISettingsParams = {
  pageTitleText: SettingsParamType.Text;
  pageDescriptionText: SettingsParamType.Text;
  noSubscriptionText: SettingsParamType.Text;
  buyNewPlanText: SettingsParamType.Text;
  cancelSubscriptionText: SettingsParamType.Text;
  pricingPlansLinkText: SettingsParamType.Text;
  storesLinkText: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  pageTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-page-title-text'),
  },
  pageDescriptionText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-page-description-text'),
  },
  noSubscriptionText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-no-subscription-text'),
  },
  buyNewPlanText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-buy-new-plan-text'),
  },
  cancelSubscriptionText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-cancel-subscription-text'),
  },
  pricingPlansLinkText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-pricing-plans-text'),
  },
  storesLinkText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.default-stores-link-text'),
  },
});
