import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  pageSubtitleFont: StyleParamType.Font;
  pageSubtitleColor: StyleParamType.Color;
  subscriptionNameFont: StyleParamType.Font;
  subscriptionNameColor: StyleParamType.Color;
  subscriptionInfoFont: StyleParamType.Font;
  subscriptionInfoColor: StyleParamType.Color;
  noSubscriptionFont: StyleParamType.Font;
  noSubscriptionColor: StyleParamType.Color;
  linkFont: StyleParamType.Font;
  linkColor: StyleParamType.Color;
  detailsLinkFont: StyleParamType.Font;
  detailsLinkColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  backgroundBorderWidth: StyleParamType.Number;
  backgroundBorderColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 26,
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  pageSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subscriptionNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  subscriptionNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subscriptionInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  subscriptionInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  noSubscriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  noSubscriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  linkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  linkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  detailsLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  backgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
